import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import footerImg from '../img/footer.png'

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="social">
                    <div>
                    <p>Follow Us</p>
                    </div>
                <div>
                <a href="https://www.facebook.com/KCTurbos/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebook} size="2x"/>
                </a>
                <a href="https://www.instagram.com/kcturbos/?hl=en" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2x"/>
                </a>
                <a href="https://www.youtube.com/channel/UC9hA54d5mBw4sAsspYGtOdw/featured?disable_polymer=true" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faYoutube} size="2x"/>
                </a>
                <a href="mailto:Sales@kcturbos.com" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faEnvelope} size="2x"/>
                </a>
                </div>
                </div>
                <div className="footer-img">
                    <img src={footerImg} alt="KC Turbos"/>
                    <p>Copyright © 2020 KC Turbos</p>
                </div>
            </footer>
        )
    }
}
