import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

import holeShot from '../img/holeshot.png';

export default class Tickets extends Component {
    render() {
        return (
        <div className="tickets">
            <div className="holeshot">
                <img src={holeShot} alt='holeshot' />
            </div>
            <div className="info">
                <div className="info-date">
                <FontAwesomeIcon icon={faCalendar} size="3x"/>
                <h1>Saturday<br/>March 5, 2022<br/> at 8 AM Mst</h1>
                </div>
                
            </div>
            <div className="link-container">
                <h1>Buy your tickets today!</h1>
                <a href={'https://kcturbos.com/products/kc-turbos-ultimate-truck-showdown?_pos=1&_sid=a4c243e32&_ss=r&fbclid=IwAR01H5Wjd8rG0bqhFsprrqp0YEHeomevZrcUyLnj9t8c2nBEaq3hwyGzGqg'} target="_blank" rel="noreferrer">
                <div className="link">
                <p className="link-text">CLICK HERE</p>
                 
                
                </div>
                </a>
            </div>
        
      </div>
        )
    }
}
