import React, { Component } from 'react';
import ReactPlayer from 'react-player';

export default class Showcase extends Component {
    render() {
        return (
            <div className="showcase">
                <div className="video">
                <ReactPlayer url='https://fb.watch/27FPE_h3fR/'
                muted={true}
                playing={true}
                controls={true}
                loop={true}
                width='100%'
                height='100%'/>
                </div>
                <div className="showcase-text">
                    <h1>KC Turbos Ultimate Truck Showdown</h1>
                    <p>Part of UCC's West Coast Hole Shot Diesel Series. The Ultimate Truck Showdown Will Feature Gas and Diesel Truck Classes to Fighting their way to the Final 1/8th mile Truck Showdown.
                    Dyno Showdown
                    Burnout Challenge
                    Classic Car Show
                    Show-N-Shine &amp; More!</p>
                </div>
            </div>
        )
    }
}
