import './App.css';

import header from './img/header.png'
import Tickets from './components/Tickets';
import Showcase from './components/Showcase';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <header className="header">
        <img src={header} alt="KC Turbos"/>
      </header>
      
      <Tickets></Tickets>
      <Showcase></Showcase>
      <Footer></Footer>
    </div>
  );
}

export default App;

